export default {
    loggedIn({ commit }, {accessToken, user}) {
        commit('SET_TOKEN', accessToken);
        commit('SET_USER', user);
    },
    
    resetState({ commit }) {
        commit('RESET_STATE');
    },
    
    // Logs out the  user.
    logout({ commit }) {
        commit('SET_TOKEN', '');
        commit('SET_USER', {});
    },
};
