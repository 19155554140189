import actions from '@/modules/auth/store/actions';
import getters from '@/modules/auth/store/getters';
import mutations from '@/modules/auth/store/mutations';
import defaultState from '@/modules/auth/store/defaults';

const state = defaultState();

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
