import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/modules/auth/store';
import projects from '@/modules/projects/store';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key),
            },
            paths: [
                'auth.accessToken',
                'auth.user',
            ],
        }),
    ],
    modules: {
        auth,
        projects
    },
});
