import Vue from 'vue'
import Router from 'vue-router'

// Public routes
import authRoutes from '@/modules/auth/routes.js';

// Auth routes
import projectsRoutes from '@/modules/projects/routes.js';

// Import private routes
import store from '@/store';
// import store from '@/store';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        ...authRoutes,
        ...projectsRoutes,
    ],
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
    
    // Check if auth is required on this route
    // (including nested routes).
    const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

    // If auth isn't required for the route, just continue.
    if (!authRequired) {
        // Check if is first route
        if (routeTo.path === '/') {
            store.getters['auth/isAuthenticated'] ? next({
                path: '/projects',
                query: routeTo.query
            }) : next({path: '/login', query: routeTo.query});
        }
        return next();
    }
    
    // If auth is required and the user is logged in...
    if (store.getters['auth/isAuthenticated']) {
        return next();
    }
    
    // If auth is required and the user is NOT currently logged in,
    // redirect to login.
    next({path: '/login'});
});

export default router
