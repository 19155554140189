import Vue from 'vue';

const vueInstance = new Vue();

function _createNotification(title, message, type = 'error', showClose = true) {
	vueInstance.$notify({
		title,
		message,
		position: 'bottom-right',
		showClose,
		type,
	});
}

function _createMessage(message, type = 'success', showClose = true) {
	vueInstance.$message({
		message,
		type,
		showClose,
	});
}

const notificationService = {
	/**
	 * @desc catch all types of errors
	 * @param error
	 * @param title
	 * @param showClose
	 */
	error(error, title = 'Errore!', showClose) {
			if(error.data && error.data.errors) {
				let errorMessage = error.data.errors
				Object.keys(errorMessage).forEach((key) => {
					if (typeof errorMessage[key] === 'string') {
						return _createNotification(title, errorMessage[key], 'error', showClose);
					} else {
						errorMessage[key].forEach(async (message) => {
							await new Promise(resolve => setTimeout(resolve, 1)).then(() => {
								return _createNotification(title, message, 'error', showClose)
							})
						});
					}
				});
			} else if(error && error.data && error.data.message){
				let errorMessage = error.data.message;
				return _createNotification(title, errorMessage, 'error', showClose)
			} else {
				return _createNotification(title, error, 'error', showClose);
		}
	},
	warning(message, showClose) {
		return _createMessage(message, 'warning', showClose);
	},
	success(message, showClose) {
		return _createMessage(message, 'success', showClose);
	},
	info(message, showClose) {
		return _createMessage(message, 'info', showClose);
	},
};


notificationService.install = (Vue) => {
	Vue.prototype.$notificationService = notificationService;
};

export default notificationService;
