
export default [
    {
        path: '/projects',
        component: () => import('@/layouts/master'),
        redirect: '/projects',
        meta: {
            authRequired: true,
            title: 'Progetti',
        },
        children: [
            {
                path: '',
                component: () => import('@/modules/projects/pages/projects'),
                name: 'projects',
                meta: {
                    authRequired: true,
                },
            }, {
                path: ':id',
                component: () => import('@/modules/projects/pages/project'),
                name: 'project',
                meta: {
                    authRequired: true,
                    title: ''
                },
            }],
    },
    {
        path: '/change-password',
        name: 'changePassword',
        redirect: '/change-password',
        component: () => import('@/layouts/master'),
        meta: {
            authRequired: true,
            title: 'Cambia Password',
        },
        children: [
            {
                path: '',
                component: () => import('@/modules/projects/pages/changePassword'),
                name: 'changePassword',
                meta: {
                    authRequired: true,
                },
            }
        ]
    },
];
