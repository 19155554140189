export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/modules/auth/pages/login'),
        meta: {
            authRequired: false,
            title: 'Login',
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/modules/auth/pages/register'),
        meta: {
            authRequired: false,
            title: 'Register',
        },
    },
];
