import Vue from 'vue'
import App from './App.vue'

// Element, based component library
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/it';
import VueSimpleSVG from 'vue-simple-svg'
import Modal from '@/components/modal';

// Import general route file
import router from '@/router/index';

// Css
import '@/styles/index.css';

// Import general store
import store from '@/store';

// Import global filters
import "@/filters/global.js"

import notificationService from '@/services/notificationService';


Vue.use(ElementUI, {locale, size: 'small'});
Vue.use(VueSimpleSVG)
Vue.use(notificationService)
Vue.use(Modal);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
