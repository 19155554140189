import defaultState from '@/modules/projects/store/defaults';

const RESET_STATE = (state) => {
    Object.assign(state, defaultState());
};
const SET_SELECTED_PROJECT = (state, value) => {
    state.selectedProject = value;
};
const NEED_TO_REFRESH_PROJECT = (state, value) => {
    state.needToRefreshProject = value;
};
const SET_USERS = (state, users) => {
    state.users = users
}

export default {
    RESET_STATE,
    SET_SELECTED_PROJECT,
    SET_USERS,
    NEED_TO_REFRESH_PROJECT
};
