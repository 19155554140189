import Vue from 'vue'

Vue.filter('currency', function (value) {
    //return parseFloat(value).toFixed(2) + '€';
    return parseFloat(value).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
});

Vue.filter('quantity', function (value) {
    return parseFloat(value).toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})
});
