import defaultState from '@/modules/auth/store/defaults';

const RESET_STATE = (state) => {
    Object.assign(state, defaultState());
};

const SET_TOKEN = (state, value) => {
    state.accessToken = `Bearer ${ value }`;
};

const SET_USER = (state, user) => {
    state.user = user;
};

export default {
    RESET_STATE,
    SET_TOKEN,
    SET_USER,
};
