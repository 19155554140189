export default {
    setSelectedProject({ commit }, project) {
        commit('SET_SELECTED_PROJECT', project);
    },
    
    needToRefreshProject({ commit }, data) {
        commit('NEED_TO_REFRESH_PROJECT', data);
    },
    
    setUsers({ commit }, users) {
        commit('SET_USERS', users)
    },

    resetState({ commit }) {
        commit('RESET_STATE');
    },
};
