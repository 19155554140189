<template>
  <el-dialog
      :visible.sync="isOpen"
      :title="title"
      :before-close="onCancelPressed"
      @closed="$el.remove()"
      :custom-class="classes"
  >
    <div>
      <component
          :is="body"
          v-loading="isLoading"
          :body-data="bodyProps"
          @validate="onValidate"
          @change="onChange"
      />
    </div>
    <span
        v-if="hasFooter"
        slot="footer"
        class="dialog-footer"
    >
			<el-button
          :loading="isLoading"
          @click="onCancelPressed"
      >{{ cancelButtonText }}</el-button>
			<el-button
          :disabled="!isValid"
          :loading="isLoading"
          type="primary"
          @click="onConfirmPressed"
      >{{ confirmButtonText }}</el-button>
		</span>
  </el-dialog>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      default: '',
    },
    body: {
      type: Object,
      default: null,
    },
    bodyProps: {
      type: Object,
      default: null,
    },
    confirmButtonText: {
      type: String,
      default: () => {
        return 'Conferma';
      },
    },
    cancelButtonText: {
      type: String,
      default: 'Annulla',
    },
    hasFooter: {
      type: Boolean,
      default: false,
    },
    onConfirm: {
      type: Function,
      required: true,
      default: () => {
        this.onConfirmPressed();
      },
    },
    onCancel: {
      type: Function,
      default: () => {
        this.close();
      },
    },
    classes:{
      type: String,
      default:''
    }
  },
  data() {
    return {
      isOpen: false,
      isValid: true,
      isLoading: false,
      bodyData: null,
    };
  },
  mounted() {
    this.isOpen = true;
  },
  methods: {
    /**
     * Callback for dynamic component validation event
     *
     * @param isValid
     */
    onValidate(isValid) {
      this.isValid = isValid;
    },
    /**
     * Callback for dynamic component data change
     *
     * @param data
     */
    onChange(data) {
      this.bodyData = data;
    },
    /**
     * Callback for confirmation event
     *
     * for the moment it will return the instance and data directly in callback
     * but you should use getData,startLoading etc if you need them by assign it to a variable
     *  - this.modalCallback = this.$modal.open('Lorem ipsum dolor', ProductForm, this.onConfirm, this.onCancel);
     *  and then
     *  this.modalCallback.getData() or this.modalCallback.startLoading()
     */
    onConfirmPressed() {
      this.onConfirm({
        instance: this,
        data: this.bodyData,
      });
    },
    /**
     * Callback for confirmation event
     *
     * for the moment it will return the instance and data directly in callback
     * but you should use getData,startLoading etc if you need them by assign it to a variable
     *  - this.modalCallback = this.$modal.open('Lorem ipsum dolor', ProductForm, this.onConfirm, this.onCancel);
     *  and then
     *  this.modalCallback.getData() or this.modalCallback.startLoading()
     */
    onCancelPressed() {
      this.close();
      this.onCancel({
        instance: this,
        data: this.bodyData,
      });
    },
    /**
     * Set modal as loading
     */
    startLoading() {
      this.isLoading = true;
    },
    /**
     * Stop modal from loading
     */
    stopLoading() {
      this.isLoading = false;
    },
    getData() {
      return this.bodyData;
    },
    /**
     * Close modal
     */
    close() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss">
.el-dialog {
  margin-top: 10vh !important;
  width: 90%;

  &__header {
    @apply border-b;
    @apply border-gray-200;
    @apply p-4;

    .el-dialog__headerbtn {
      top: 16px;
    }
  }

  &__body {
    max-height: 70vh;
    overflow: auto;
    padding: 20px 20px;
  }

  &__footer {
    @apply border-t;
    @apply border-gray-200;
    @apply p-4;
  }
}
</style>
