import Vue from 'vue';
import Modal from './modal';

/**
 * Create new modal instance and append to DOM
 *
 * @param propsData
 * @returns {{stopLoading: default.methods.stopLoading, startLoading: default.methods.startLoading, close: *}}
 */
function modal(propsData) {
    // Extend modal
    const instance = Vue.extend(Modal);
    
    // Create new instance
    let modalInstance = new instance({
        el: document.getElementById('app').appendChild(document.createElement('div')),
        propsData,
    });
    
    // Return instance of modal
    return {
        'close': modalInstance.close,
        'startLoading': modalInstance.startLoading,
        'stopLoading': modalInstance.stopLoading,
        'getData': modalInstance.getData,
    };
}

const ModalProgrammatic = {
    
    /**
     * Open modal
     *
     * @returns {{stopLoading: default.methods.stopLoading, startLoading: default.methods.startLoading, close: *}}
     * @param title
     * @param body
     * @param bodyProps
     * @param hasFooter
     * @param onConfirm
     * @param onCancel
     * @param confirmButtonText
     * @param classes
     */
    open(title, body, bodyProps, onConfirm, onCancel = () => {
    }, hasFooter = true, confirmButtonText = 'Conferma',classes = "") {
        
        return modal({title, body, bodyProps,confirmButtonText, hasFooter, onConfirm, onCancel,classes });
    },
};

const Plugin = {
    install(Vue) {
        Vue.prototype.$modal = ModalProgrammatic;
    },
};

Vue.use(Plugin);
export default Plugin;
